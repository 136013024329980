<template>
  <div class="all-progress">
    <el-progress :show-text="false" text-inside text-color="#409EFF" 
      :percentage="progress" ></el-progress>
    <div class="answer">
      <el-form  ref="form" :model="form" label-width="80px">
          <el-form-item label="题干信息">
            <div class="textarea-div">
              <pre> {{current.title}} </pre></div>
          </el-form-item>
          <el-form-item v-if="current.type.trim() == 'C'"  label="多选答题">
            <el-checkbox-group  v-model="form.c">
              <el-checkbox label="a" >{{current.a}}</el-checkbox>
              <el-checkbox label="b" >{{current.b}}</el-checkbox>
              <el-checkbox label="c" >{{current.c}}</el-checkbox>
              <el-checkbox label="d" >{{current.d}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item v-if="current.type.trim() == 'B'" label="单选答题">
            <el-radio-group v-model="form.b">
              <el-radio label="a">{{current.a}}</el-radio>
              <el-radio label="b">{{current.b}}</el-radio>
              <el-radio label="c">{{current.c}}</el-radio>
              <el-radio label="d">{{current.d}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="current.type.trim() == 'A'" label="描述答题">
            <el-input type="textarea" v-model="form.a"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="previous">上一题</el-button>
            <el-button type="primary" v-if="!commit" @click="next(true)">下一题</el-button>
            <el-button type="primary" v-if="commit" @click="submit">提交试卷</el-button>
          </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  Progress,
  Input,
  Form,
  FormItem,
  Button,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  DatePicker,
  TimeSelect,
  TimePicker,
  Switch,
  Select,
  Icon,
  Row,
  Col,
  Option,
  OptionGroup,
  Alert,
  Message,
  MessageBox
} from 'element-ui';
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(DatePicker)
Vue.use(TimeSelect)
Vue.use(TimePicker)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Button)
Vue.use(RadioButton)
Vue.use(Alert)
Vue.use(Progress)
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$message = Message;
export default {
  data(){
    return {
      data:{},
      current:{},
      index:0,
      answer:[],
      form: {
        a: '',
        b: '',
        c: []
      },
      commit:false,
      progress:0
    }
  },
  created(){
    this.data = JSON.parse(localStorage.getItem("data"))
    if ( this.data == null ) {
      window.location.href = "about:blank" 
    }
    let answer = JSON.parse(localStorage.getItem("answer"))
    localStorage.setItem("type", this.$route.query.type)
    if ( answer != null && answer != "" ) {
        this.answer = answer
    }
    this.next()
  },
  methods: {
    previous(){
      let idx = localStorage.getItem("idx")
      if ( idx === null || idx === 0 || idx === '0' || idx < 0 ) {
        this.$message({
              message: '已经是第一题了!',
              type: 'warning'
            });
        return
      }
      idx = parseInt(idx)
      localStorage.setItem("idx", idx-1)
      this.form = {
        a: '',
        b: '',
        c: []
      }
      this.next();
    },
    next(tag) {
      let idx = localStorage.getItem("idx")
      if ( idx === null || idx === 0 || idx === '0' || idx < 0 ) {
        idx = 0
      }
      idx = parseInt(idx)
      let answer = ""
      let ankey = ""
      for ( let key in this.form) {
        if ( this.form[key] instanceof Array ) {
          if ( this.form[key].length > 0 ) {
            ankey = key
            answer = this.form[key].join()
          }
        } else {
          if ( this.form[key] != '' ) {
            ankey = key
            answer = this.form[key].toString()
          }
        }
      }
      if ( tag == true && ( answer == null || answer == "" ) ) {
        this.$message({
              message: '请对当前问题 作答!',
              type: 'warning'
            });
        return 
      }
      if ( answer != null && answer != "" ) {
        this.answer[idx] = {}
        this.answer[idx]['answer'] = answer;
        this.answer[idx]['key'] = ankey;
        if ( tag == true && this.data.length > idx ) {
          idx++
          localStorage.setItem("idx", idx)
        }
      }
      if ( this.data.length > idx && idx >= 0 ) {
        this.form = {
          a: '',
          b: '',
          c: []
        }
        this.progress = Math.floor(( idx / this.data.length ) * 100)
        this.current = this.data[idx]
        if (this.data.length == idx+1) {
          this.commit = true
        } else {
          this.commit = false
        }
        if ( this.answer[idx] && this.answer[idx]['answer'] ) {
          if ( this.answer[idx]['key'] == "c" ) {
            this.form[this.answer[idx]['key']]=this.answer[idx]['answer'].split(",")
          } else {
            this.form[this.answer[idx]['key']]=this.answer[idx]['answer']
          }
        }
        localStorage.setItem("answer", JSON.stringify(this.answer))
      } else {
        this.$message({
              message: '已经是最后一题了!',
              type: 'warning'
            });
      }
    },
    submit(){
      this.next() 
      const count = this.data.length
      let idx = 0
      this.answer.forEach((element, index) => {
        console.log( index, element )
        idx++
      });
      console.log( idx , count  )
      if ( idx < count ) {
        this.$message({
              message: '试卷有未完成的答题, 不能提交!',
              type: 'warning'
            });
      } else {
        axios.post('http://answer.80boys.com/submit.php', {
            answer: this.answer,
            username:localStorage.getItem("student"),
            type: localStorage.getItem("type")
          })
          .then((response)=> {
            if (response.status == 200) {
              if ( response.data.status == 200 ) {
                localStorage.clear();
                this.$alert('试卷提交成功!', '恭喜完成考试', {
                  confirmButtonText: '确定',
                  callback: action => {
                    window.location.href = "about:blank" 
                  }
                });
              }
            }
          })
          .catch(function (error) {
            this.$message({
              message: '服务端接口异常!!',
              type: 'warning'
            });
          });
      }
    }
  },
  name: 'Answer'
}
</script>

<style >
.all-progress{
  width: 698px;
  margin: 0 auto;
  margin-top: 10vh;
}
.answer{
  width: 600px;
  margin: 0 auto;
  border: 1px solid #eee;
  padding: 3rem;
}
.el-form-item__label-wrap {
  margin-left: 0px !important;
}
.textarea-div {
  width: 100%;
    min-height: 200px;
    border: 1px solid #eee;
    user-select: none;
}
.textarea-div pre{
  word-break: break-all;
    white-space: pre-wrap;
    word-wrap: break-word;
}
.answer .el-form .el-form-item .el-checkbox__label {
    white-space: pre !important;
}
</style>