<template>
    <div class="details">
        <el-card v-for="(item, ind) in data.data" :key="ind" class="box-card">
            <div slot="header" class="clearfix">
                <span>{{item.title}}</span>
                <p style="float: right; padding: 3px 0">{{ data.answer.username }} - {{ data.answer.createtime }}</p>
            </div>
            <div v-if="item.type.trim() != 'A'"  >
                (A) : <el-tag type="success">{{item.a}}</el-tag><br/>
                (B) : <el-tag type="success">{{item.b}}</el-tag><br/>
                (C) : <el-tag type="success">{{item.c}}</el-tag><br/>
                (D) : <el-tag type="success">{{item.d}}</el-tag><br/>
                <el-divider></el-divider>
            </div>
            <div v-if="item.type.trim() == 'A'" class="text item">
                {{data.answer.answer[ind]["answer"]}}
            </div>
            <div v-if="item.type.trim() == 'B'" class="text item">
                ({{ data.answer.answer[ind]["answer"].toUpperCase() }}) : {{ item[data.answer.answer[ind]["answer"]] }}
            </div>
            <div v-if="item.type.trim() == 'C'" class="text item">
                <p v-for="(it, i) in data.answer.answer[ind]['answer'].split(',')" :key="i" >
                    ({{it.toUpperCase()}}) : {{ item[it] }}
                </p>
            </div>
        </el-card>
        
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  import {
    Tag,
    Card,
    Input,
    Form,
    Divider,
    Button,
    Message
  } from 'element-ui';
  Vue.use(Card)
  Vue.use(Input)
  Vue.use(Divider)
  Vue.use(Tag)
  Vue.use(Button)
  Vue.prototype.$message = Message;
  export default {
    data() {
      return {
        data: [],
        type:2
      };
    },
    created(){
        axios.get('http://answer.80boys.com/list.php', {
            params: {
              type: this.$route.query.type,
              username: this.$route.query.username
            }
          })
          .then((response)=> {
            if (response.status == 200) {
              this.data = response.data.data
              response.data.data.answer.answer = JSON.parse(response.data.data.answer.answer)
              this.data.answer = response.data.data.answer
            }
          })
          .catch(function (error) {
            console.log( error )
            this.$message({
              message: '服务端接口异常!!',
              type: 'warning'
            });
          });
    },
    methods: {
    },
    name: 'Details'
  }
  </script>
  
  <style scoped >
  .details{
    width: 800px;
    margin: 0 auto;
  }
  .text {
    font-size: 14px;
  }
  .item {
    margin-bottom: 18px;
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .box-card{
    margin-top: 2rem;
  }
  </style>