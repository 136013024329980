<template>
  <div class="home">
    <p><h2>请输入学生姓名</h2></p> <br />
    <el-form :model="validateForm" ref="validateForm" label-width="100px" class="demo-ruleForm">
      <el-form-item
        label="学生姓名"
        prop="username"
        :rules="[
          { required: true, message: '名字不能为空'},
          { type: 'string', message: '名字必须为字符串'}
        ]"
      >
        <el-input v-model.string="validateForm.username" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('validateForm')">提交</el-button>
        <el-button @click="resetForm('validateForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  Input,
  Form,
  FormItem,
  Button,
  MessageBox,
  Message
} from 'element-ui';
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Button)
Vue.prototype.$message = Message;
export default {
  data() {
    return {
      validateForm: {
        username: ''
      }
    };
  },
  methods: {
    submitForm(formName) {
      const  qtype = this.$route.query.type
      if ( qtype == null  ) {
        return this.$message({
              message: '参数错误, 请重新打开地址!!',
              type: 'warning'
            });
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          localStorage.setItem("student", this.validateForm.username.trim() );
          axios.get('http://answer.80boys.com/index.php', {
            params: {
              type: qtype
            }
          })
          .then((response)=> {
            if (response.status == 200) {
              localStorage.setItem( "data", JSON.stringify(response.data) );
              this.$router.push({name :"answer", query:{type:qtype}})  
            }
          })
          .catch(function (error) {
            this.$message({
              message: '服务端接口异常!!',
              type: 'warning'
            });
          });
        } else {
          this.$message({
              message: '您的姓名填写不正确',
              type: 'warning'
            });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  name: 'HomeView',
  components: {}
}
</script>

<style scoped >
.home{
  width: 400px;
  margin: 0 auto;
  margin-top: 4vh;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem;
  padding-right: 4rem;
}
</style>