<template>
    <div class="list">
      <el-table
        :data="tableData"
        style="width: 100%" 
        :row-class-name="tableRowClassName" >
        <el-table-column
          prop="createtime"
          label="日期"
          width="180">
        </el-table-column>
        <el-table-column
          prop="username"
          label="姓名"
          width="180">
        </el-table-column>
        <el-table-column
          prop="tag"
          label="操作"
          filter-placement="bottom-end">
          <template slot-scope="scope">
            <el-button  type="primary" @click="toDetails(scope.row, item)" icon="el-icon-share"  v-for="(item, ind) in scope.row.type.split(',')" :key="ind">
              {{item}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  import {
    Input,
    Form,
    FormItem,
    Button,
    Message,
    Table,
    TableColumn
  } from 'element-ui';
  Vue.use(Input)
  Vue.use(Form)
  Vue.use(FormItem)
  Vue.use(Button)
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Table)
  Vue.use(Table)
  Vue.prototype.$message = Message;
  export default {
    data() {
      return {
        tableData: [],
        qtype:2
      };
    },
    computed: {
        tableRowClassName({row, rowIndex}) {
          if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
        }
    },
    methods: {
      toDetails(row, type){
        this.$router.push({name:"details", query:{username:row.username, type:type}})
      }
    },
    created(){
        axios.get('http://answer.80boys.com/answer.php', {
            params: {
              type: 1
            }
          })
          .then((response)=> {
            if (response.status == 200) {
              console.log( response.data.data )
              this.tableData = response.data.data;
            }
          })
          .catch(function (error) {
            this.$message({
              message: '服务端接口异常!!',
              type: 'warning'
            });
          });
    },
    name: 'List'
  }
  </script>
  
  <style scoped >
  .list{
    width: 800px;
    margin: 0 auto;
  }
  .warning-row {
    background: oldlace;
  }

  .success-row {
    background: #f0f9eb;
  }
  </style>